import React from 'react';
import styled from 'styled-components';
import placeholderPic from '../assets/images/product-placeholder-01.jpg';
import cheesesSubHeader from '../assets/images/blues-sub-header.jpg';

const cheeseRange = require('../assets/data/cheese-range.json');

const CheesesHeader = styled.div`
  background: url(${cheesesSubHeader});
  background-size: cover;
  background-position: bottom center;
  min-height: 200px;
  margin-bottom: 40px;
  vertical-align: middle;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    margin-bottom: 10px;
  }

  div {
    text-align: left;
    max-width: 1100px;
    width: 100%;
  }

  h3 {
    font-family: BrandFont;
    font-size: 3.5rem;
    margin-bottom: 0px;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: inline-block;
    color: white;
  }
`;

const Category = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const TableContainer = styled.div`
  table td {
    padding: 3px;
  }

  table tr:nth-of-type(odd) td {
    background-color: skyblue;
  }
`;

export default function CheesesFullListPage() {
  const sortedCheeses = Array.from(cheeseRange);
  sortedCheeses.sort((cheeseA, cheeseB) =>
    cheeseA.name.localeCompare(cheeseB.name)
  );

  const [cheeseTypes, setCheeseTypes] = React.useState([
    'cow',
    'sheep',
    'goat',
  ]);
  const [filteredResults, setFilteredResults] = React.useState(sortedCheeses);

  const handleChange = (e) => {
    const savedEvent = e;
    const savedTarget = e.currentTarget;
    // e.persist();
    console.log(savedTarget);
    console.log(savedTarget.checked);
    if (savedTarget.checked) {
      setCheeseTypes((cheeseTypes) => [...cheeseTypes, savedTarget.value]);
    } else {
      setCheeseTypes(cheeseTypes.filter((item) => item !== savedTarget.value));
    }
    console.log(cheeseTypes);
  };

  console.log('cheeseTypes initial', cheeseTypes);

  React.useEffect(() => {
    let results = [];
    results = sortedCheeses.filter((cheese) =>
      cheeseTypes.includes(cheese.type)
    );
    setFilteredResults(results);
  }, [cheeseTypes]);

  return (
    <>
      <CheesesHeader>
        <div className="content-mw-1100">
          <h3>Full Product List - (Demo purposes only)</h3>
        </div>
      </CheesesHeader>

      <div className="content-mw-1100 ">
        <div className="three-col-grid grid-no-scale cheeses-range">
          {filteredResults.map((cheese, iCheese) => (
            <div
              className="card"
              style={{ backgroundColor: '#dfdfdf' }}
              key={iCheese}
            >
              <div>
                <div>
                  <Category>
                    <img
                      src={`/images/category-badge-${cheese.type}.png`}
                      alt=""
                    />
                  </Category>
                  <a href={`/cheeses/${cheese.slug}`}>
                    <img
                      src={
                        cheese.image
                          ? `/images/products/${cheese.image}`
                          : placeholderPic
                      }
                      alt=""
                    />
                  </a>
                  <br />
                </div>
              </div>
              <TableContainer>
                <table>
                  <tr>
                    <td style={{ width: '30%' }}>Name</td>
                    <td>{cheese.name}</td>
                  </tr>
                  <tr>
                    <td>Milk</td>
                    <td>{cheese.type}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{cheese.description}</td>
                  </tr>
                  <tr>
                    <td>Flavour</td>
                    <td>{cheese.flavour}</td>
                  </tr>
                  <tr>
                    <td>Texture</td>
                    <td>{cheese.texture}</td>
                  </tr>
                  <tr>
                    <td>Colour</td>
                    <td>{cheese.color}</td>
                  </tr>
                  <tr>
                    <td>Age Profile</td>
                    <td>{cheese.ageProfile}</td>
                  </tr>
                  <tr>
                    <td>Price per/kg</td>
                    <td>
                      &euro;{parseInt(cheese.pricePerKg / 100).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Melt</td>
                    <td>{cheese.melt}</td>
                  </tr>
                </table>
              </TableContainer>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
